import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CxOneVersion } from "raci-react-library";
import { utilityRoutes } from "../../routing/routes.config";
import { useApiClientProxy } from "../useApiClient";

export const useLoadWebChatScript = () => {
  const client = useApiClientProxy();
  const location = useLocation();
  const [pointOfContactId, setPointOfContactId] = useState("");
  const [shouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [crmId, setCrmId] = useState("");
  const [getCxOneVersion, setCxOneVersion] = useState(CxOneVersion.Max);

  const disallowPages = [
    ...utilityRoutes.map((route) => route.path.toLowerCase()),
    process.env.REACT_APP_HOMEPAGE?.toLowerCase(),
  ];
  const currentLocation = location.pathname.toLowerCase();

  const shouldGetWebChatInformation = !disallowPages?.includes(currentLocation);

  useEffect(() => {
    let isCancelled = false;

    const getWebChatId = async () => {
      try {
        if (!isCancelled) {
          if (shouldGetWebChatInformation) {
            const response = await client.getWebChatContactId();
            setPointOfContactId(response.result.pointOfContactId ?? "");
            setCrmId(response.result.crmId ?? "");
            setShouldRenderWebChat(true);
            setCxOneVersion(response.result.useWebChatCxOneAgent ? CxOneVersion.Agent : CxOneVersion.Max);
          } else {
            setShouldRenderWebChat(false);
          }
        }
      } catch {
        setPointOfContactId("");
        setShouldRenderWebChat(false);
      }
    };

    getWebChatId();

    return () => {
      isCancelled = true;
    };
  }, [client, currentLocation, shouldGetWebChatInformation]);

  return { 
    pointOfContactId: pointOfContactId, 
    crmId: crmId, 
    shouldRender: shouldRenderWebChat,
    cxOneVersion: getCxOneVersion,
  };
};

export default useLoadWebChatScript;
