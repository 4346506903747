import { Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "@racwa/react-components";
import {
  Payment,
  PaymentFrequency,
  PaymentMethod,
  useBsbLookup,
  useFormotiv,
  useGetSessionState,
  useSessionState,
} from "raci-react-library";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { useLookupApiClient } from "../../shared/hooks/useApiClient";
import {
  PURCHASE_CONFIRMATION_PAGE_URL,
  PurchaseRoutes,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../shared/routing/routes.config";
import { YourQuoteState } from "../YourQuote/types";
import PolicySummary from "./components/PolicySummary";
import TryAgainDialog from "./components/TryAgainDialog";
import { FormProps, PaymentData, PaymentState } from "./types";

export const Form: React.FC<FormProps> = ({
  form,
  data: { hasError, premiumDetails },
  openTryAgainDialog,
  lockField,
  westpacIFrameProps,
  onSubmit,
  onDoneClick,
  onTryAgainClick,
}) => {
  const navigate = useNavigate();
  const { getValues, formState } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const [isFirstTimeValid, setIsFirstTimeValid] = useState(false);
  const { paymentFrequency } = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const { method: paymentMethod } = useGetSessionState<PaymentState>(PurchaseRoutes.Payment);
  const [frequency, setFrequency] = useState<PaymentFrequency>(paymentFrequency);
  const [method] = useState<PaymentMethod | null>(paymentMethod);
  const paymentData = useGetSessionState<PaymentData>(PurchaseRoutes.Payment);
  const [yourQuoteState, setYourQuoteState] = useSessionState<YourQuoteState>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });

  const isDisabled = lockField || paymentData.annualCCPaymentInProgress;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const lookupApiClient = useLookupApiClient();
  const { onBsbLookup } = useBsbLookup({ apiClient: lookupApiClient });

  useEffect(() => {
    const { bsb, accountNumber, accountName } = getValues();
    if (
      formState.dirtyFields.bsb &&
      formState.dirtyFields.accountNumber &&
      formState.dirtyFields.accountName &&
      bsb &&
      accountNumber &&
      accountName
    ) {
      setIsFirstTimeValid(isFirstTimeValid || true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, getValues]);

  useEffect(() => {
    setYourQuoteState({ ...yourQuoteState, paymentFrequency: frequency });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency]);

  useEffect(() => {
    if (hasError) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } else if (paymentData.isCompleted) {
      navigate(PURCHASE_CONFIRMATION_PAGE_URL);
    }
  }, [hasError, navigate, paymentData.isCompleted]);

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(formotivOnSubmitWrapper(onSubmit))} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-test-id="header" marginBottom={"24px"}>
              Purchase policy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Payment
              id="payment"
              isMobile={true}
              policySummary={<PolicySummary />}
              onBsbLookup={onBsbLookup}
              saveBranchName={false}
              frequencyProps={{
                disabled: isDisabled,
                annual: premiumDetails.annual,
                monthly: premiumDetails.monthly,
                setFrequency: setFrequency,
                isAnnualFrequency: frequency === PaymentFrequency.Annual,
              }}
              methodProps={{
                defaultValue: method ?? paymentData.annualCCPaymentInProgress ? PaymentMethod.Card : undefined,
                disabled: isDisabled,
              }}
              authorisationProps={{
                pdsUrl: process.env.REACT_APP_PDS_PED_URL,
                ddrsaUrl: process.env.REACT_APP_PDS_PED_URL,
                paymentFrequency: frequency,
              }}
              westpacIFrameProps={ westpacIFrameProps }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: isMobile ? "24px" : "" }}>
            <CallToActionButton id="submit" type="submit" color="primary" variant="contained" fullWidth>
              Purchase policy
            </CallToActionButton>
          </Grid>
        </Grid>
      </form>
      <TryAgainDialog
        open={openTryAgainDialog}
        policyNumber={paymentData.policyNumber}
        onTryAgainClick={() => onTryAgainClick()}
        onDoneClick={() => onDoneClick()}
      ></TryAgainDialog>
    </FormProvider>
  );
};

export default Form;
