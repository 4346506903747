import { PaymentMethod, useGetSessionState, useSessionState } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PaymentFrequency } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { QuoteRoutes } from "../../../../shared/routing/routes.config";
import { MoreBoatState } from "../../../MoreBoat/types";
import { FormProps, PaymentState } from "../../types";
import useBankPayment, { OnSubmitBankAccountEvent } from "../useBankPayment";
import useWestpacScript from "../useWestpacScript";

export const usePayment = (): FormProps => {
  const [state] = useSessionState<PaymentState>();
  const [showTryAgainDialog, setShowTryAgainDialog] = useState(false);
  const { quote } = useGetSessionState<MoreBoatState>(QuoteRoutes.MoreBoat);
  const { onSubmit: onSubmitBankAccount, isError: isBankAccountError } = useBankPayment();

  const form = useForm<PaymentState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: state,
    shouldUnregister: true,
  });

  const { watch } = form;
  const method = watch("method") as PaymentMethod;
  const frequency = watch("frequency") as PaymentFrequency;
  const {
    westpacIFrameProps,
    showAuthorisationCard,
    isFormFilledOut,
    isError: CreditCardError,
    ...westpacScriptProps
  } = useWestpacScript(
    method === PaymentMethod.Card,
    frequency === PaymentFrequency.Monthly,
    (openTryAgainDialog: boolean) => setShowTryAgainDialog(openTryAgainDialog),
  );

  const onSubmit = async (paymentState: PaymentState) => {
    if (paymentState.method === PaymentMethod.BankAccount) {
      const bankFrom = form.getValues();
      const paymentObject: any = paymentState ?? PaymentMethod.BankAccount;
      if (bankFrom.bsb && bankFrom.accountNumber && bankFrom.accountName) {
        await onSubmitBankAccount({
          bsb: paymentState.bsb,
          accountName: paymentState.accountName,
          accountNumber: paymentState.accountNumber,
          frequency: frequency as PaymentFrequency,
          method: paymentObject,
        } as OnSubmitBankAccountEvent);
      }
    } else if (paymentState.method === PaymentMethod.Card) {
      westpacScriptProps.onSubmitCreditCard();
    }
  };

  const onDoneClick = () => {
    westpacScriptProps.onDoneClick();
  };

  const onTryAgainClick = () => {
    westpacScriptProps.onTryAgainClick();
  };

  return {
    data: {
      premiumDetails: quote?.cover?.premiumDetails!,
      trustedFrame: westpacScriptProps.trustedFrame,
      hasError: CreditCardError || isBankAccountError,
      displayAuthorisationCard: showAuthorisationCard,
    },
    openTryAgainDialog: showTryAgainDialog,
    form,
    lockField: westpacScriptProps.isEditLocked,
    westpacIFrameProps: westpacIFrameProps,
    onSubmit,
    onDoneClick,
    onTryAgainClick,
  };
};

export default usePayment;
