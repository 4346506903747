import { RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop, SessionStatePrefixProvider, TrackPageChanges, WebChat } from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RacwaSession from "./shared/components/RacwaSession";
import { SESSION_KEY_PAGE_PREFIX } from "./shared/constants";
import Views from "./views";
import useLoadWebChatScript from "./shared/hooks/useLoadWebChatScript";

export const App: React.FC = () => (
  <RacwaThemeProvider>
    <RecoilRoot>
      <Backdrop />
      <BrowserRouter>
        <RacwaSession />
        <SessionStatePrefixProvider prefix={SESSION_KEY_PAGE_PREFIX}>
          <Views />
        </SessionStatePrefixProvider>
        <WebChat useGetWebChatInformation={useLoadWebChatScript} />
        <TrackPageChanges untrackedPages={[process.env.PUBLIC_URL, "/"]} />
      </BrowserRouter>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
