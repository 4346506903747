import { OpenBackdrop } from "raci-react-library";
import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import PreloadReferenceData from "../shared/components/PreloadReferenceData";
import useStandardPage from "../shared/hooks/useStandardPage";
import useStepperPage from "../shared/hooks/useStepperPage";
import { RouteGuard } from "../shared/routing/RouteGuard";
import { TitleGuard } from "../shared/routing/TitleGuard";
import {
  QUOTE_LETS_START_PAGE_URL,
  UTILITY_IMPORTANT_INFORMATION_URL,
  utilityRoutes,
} from "../shared/routing/routes.config";
import useRoutes from "../shared/routing/useRoutes";

const RacwaStepperPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStepperTemplate })),
);
const RacwaStandardPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStandardPageTemplate })),
);

export const Views: React.FC = () => {
  const { formRoutes } = useRoutes();
  const stepperPageProps = useStepperPage();
  const standardPageProps = useStandardPage();

  return (
    <>
      <PreloadReferenceData />
      <TitleGuard />
      <RouteGuard
        disableChecks={utilityRoutes.map((item) => (item.path === UTILITY_IMPORTANT_INFORMATION_URL ? "" : item.path))}
      />
      <Routes>
        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStepperPageTemplate {...stepperPageProps}>
                <Outlet />
              </RacwaStepperPageTemplate>
            </Suspense>
          }
        >
          <Route index element={<Navigate to={QUOTE_LETS_START_PAGE_URL} replace />} />
          {formRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>

        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStandardPageTemplate {...standardPageProps}>
                <Outlet />
              </RacwaStandardPageTemplate>
            </Suspense>
          }
        >
          {utilityRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>

        <Route path="*" element={<Navigate to={QUOTE_LETS_START_PAGE_URL} replace />} />
        <Route path="/" element={<Navigate to={QUOTE_LETS_START_PAGE_URL} replace />} />
      </Routes>
    </>
  );
};

export default Views;
