import { useMediaQuery } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import { HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY, useSessionState, useSetBackdrop } from "raci-react-library";
import React, { createRef, useEffect,  useRef,  useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  PaymentFrequency,
  PaymentMethod,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { PURCHASE_CONFIRMATION_PAGE_URL } from "../../../../shared/routing/routes.config";
import { PaymentData } from "../../types";
import useGetPaymentReferenceData from "../useGetPaymentReferenceData";
import WestpacIFrameProps from "raci-react-library/dist/interfaces/WestpacIFrameProps";

const NUMBER_OF_INPUT_FIELDS_WITHOUT_CVC = 4;
const NUMBER_OF_INPUT_FIELDS_WITH_CVC = 5;

let changedFields: string[] = [];

declare global {
  interface Window {
    QuickstreamAPI: any;
  }
}

const labelStyle = {
  color: theme.palette.text.secondary,
  "font-weight": 400,
  "font-family": "'Stag Sans Web', Helvetica, Arial, sans-serif",
  "font-size": "1.125rem",
  "margin-bottom": "0.5rem",
};

const inputStyle = {
  ...labelStyle,
  "border-radius": "3px",
  "box-shadow": "rgba(0, 0, 0, 0.075)",
  border: `1px solid ${theme.palette.grey["300"]}`,
  color: "rgb(24, 45, 60)",
  "font-size": "1.125rem",
  "font-weight": "300",
  "line-height": "21.3768px",
  padding: "10.5px 10px 10.5px 10px",
  margin: 0,
  height: "44.375px",
};

export const handleIssuePolicyException = (
  ex: any,
  setError: (isError: boolean) => void,
  onUnsuccessfulSubmit: (policyNumber: string) => void,
  onTryAgainCallback: (policyNumber?: string) => void,
  currentPolicyNumber?: string,
) => {
  const apiException = ex as ApiException;

  if (apiException.status === HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY) {
    const policyNumber = apiException.result?.policyNumber ?? currentPolicyNumber;
    const hasRetryAttempt = !!Object.keys(apiException.headers).find((key) => key.toLowerCase() === "retry-after");
    if (hasRetryAttempt) {
      onTryAgainCallback(policyNumber);
      return;
    } else if (policyNumber) {
      onUnsuccessfulSubmit(policyNumber);
      return;
    }
  }

  setError(true);
};

export interface WestpacScriptResult {
  westpacIFrameProps: WestpacIFrameProps;
  trustedFrame: React.MutableRefObject<TrustedFrame | null>;
  onSubmitCreditCard: () => void;
  showAuthorisationCard: boolean;
  isFormFilledOut: boolean;
  isError: boolean;
  isEditLocked: boolean;
  policyNumber: string;
  onDoneClick: () => void;
  onTryAgainClick: () => void;
}

interface QuickStreamEvent {
  eventType: string;
  fieldName: string;
  errorMessages?: string[];
}

interface TrustedFrame extends React.MutableRefObject<any | null> {
  submitForm(callBack: any): () => void;
  teardown: (callback?: (errors: Error[], data: any) => void) => void;
  setEventHandler: (
    fieldName: "cardholderName" | "cardNumber" | "expiryDateMonth" | "expiryDateYear" | "cvn",
    event: "focus" | "change" | "error" | "blur",
    handler: (event: QuickStreamEvent) => void,
  ) => void;
}

function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

const useWestpacScript = (
  isCreditCardSelected: boolean,
  isPaymentFrequencyMonthly: boolean,
  openTryAgainDialogFunction: (openTryAgainDialog: boolean) => void,
): WestpacScriptResult => {
  const initialTrustedFrameState = React.createRef<TrustedFrame | null>();
  const trustedFrame = React.useRef<TrustedFrame | null>(initialTrustedFrameState.current);

  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const apiClient = useApiClient();
  const { paymentReferenceData, isError: paymentReferenceDataIsError } = useGetPaymentReferenceData();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [policyNumber, setPolicyNumber] = useState("");
  const [currentPaymentState, setPaymentState] = useSessionState<PaymentData>();
  const [showAuthorisationCard, setShowAuthorisationCard] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEditLocked, setIsEditLocked] = useState(false);

  const initialCreditCardContainer = createRef<HTMLDivElement | null>();
  const creditCardContainer = useRef<HTMLDivElement | null>(initialCreditCardContainer.current);

  const westpacIFrameProps: WestpacIFrameProps = {
    creditCardContainerRef: creditCardContainer,
    isWestpacIFrameLoading: !paymentReferenceData?.westpacPublishableApiKey,
  };

  const handleUnsuccessfulSubmit = (policyNumber: string) => {
    setPaymentState({
      isCompleted: true,
      isSuccessful: false,
      policyNumber,
      lockdownPreviousPage: false,
      annualCCPaymentInProgress: false,
    });
  };

  const onSubmitCreditCard = async () => {
    if (trustedFrame && trustedFrame.current) {
      trustedFrame.current.submitForm(async (errors: any, data: any) => {
        if (errors) {
          // Handle errors here
        } else {
          const singleUseTokenId = data.singleUseToken.singleUseTokenId;

          const form = document.getElementById("CreditCardpaymentform");
          window.QuickstreamAPI.creditCards.appendTokenToForm(form, singleUseTokenId);

          const performPurchase = async () => {
            try {
              setBackdrop(true);
              const response = await apiClient.policies({
                frequency: isPaymentFrequencyMonthly ? PaymentFrequency.Monthly : PaymentFrequency.Annual,
                method: PaymentMethod.Card,
                token: singleUseTokenId,
              });
              setPaymentState({
                isCompleted: true,
                isSuccessful: true,
                policyNumber: response.result.policyNumber,
                receiptNumber: response.result.receiptNumber,
                total: response.result.total,
                lockdownPreviousPage: false,
                annualCCPaymentInProgress: false,
              });

              navigate(PURCHASE_CONFIRMATION_PAGE_URL);
            } catch (ex) {
              const handleTryAgain = (policyNumber?: string) => {
                setPolicyNumber(policyNumber ?? "");
                openTryAgainDialogFunction(true);
                setIsEditLocked(!!policyNumber);
                setPaymentState({
                  lockdownPreviousPage: !isPaymentFrequencyMonthly,
                  annualCCPaymentInProgress: !isPaymentFrequencyMonthly,
                  isSuccessful: false,
                  policyNumber: policyNumber ?? "",
                });

                !policyNumber || policyNumber === ""
                  ? gtm(event("Something went wrong - can't setup payment error"))
                  : gtm(event("Something went wrong - process payment error"));
              };
              handleIssuePolicyException(
                ex,
                setIsError,
                handleUnsuccessfulSubmit,
                handleTryAgain,
                currentPaymentState.policyNumber,
              );
            } finally {
              setBackdrop(false);
            }
          };
          await performPurchase();
        }
      });
    }
  };

  useEffect(() => {
    setShowAuthorisationCard(false);
    if (!isCreditCardSelected && trustedFrame.current) {
      trustedFrame.current.teardown();
      trustedFrame.current = null;
      changedFields = [];
    }
  }, [isCreditCardSelected]);

  useEffect(() => {
    let isMounted = true;

    const trackCompletion = (frame: TrustedFrame | null) => {
      if (!frame) {
        return;
      }

      changedFields = [];

      const trackChanges = (event: QuickStreamEvent) => {
        const newChangedFields = [...changedFields, event.fieldName].filter(onlyUnique);
        changedFields = newChangedFields;
        const fieldsCount = isPaymentFrequencyMonthly
          ? NUMBER_OF_INPUT_FIELDS_WITHOUT_CVC
          : NUMBER_OF_INPUT_FIELDS_WITH_CVC;
        setShowAuthorisationCard(changedFields.length === fieldsCount);
      };

      frame.setEventHandler("cardNumber", "change", trackChanges);
      frame.setEventHandler("cardholderName", "change", trackChanges);
      frame.setEventHandler("cvn", "change", trackChanges);
      frame.setEventHandler("expiryDateMonth", "change", trackChanges);
      frame.setEventHandler("expiryDateYear", "change", trackChanges);
    };

    const createTrustedFrameHandler = (errors: any, data: any) => {
      setShowAuthorisationCard(false);
      if (errors) {
        // Handle errors here
      } else {
        trustedFrame.current = data.trustedFrame;
        trackCompletion(trustedFrame.current);
      }
    };

    const getFrameHeight = (isMonthly: boolean): string => {
      if (isMobile && !isMonthly) {
        return "480px";
      } else if (isMobile && isMonthly) {
        return "380px";
      }
      return isMonthly ? "320px" : "420px";
    };

    if (
      isMounted &&
      window?.QuickstreamAPI &&
      isCreditCardSelected &&
      !paymentReferenceDataIsError &&
      paymentReferenceData?.westpacPublishableApiKey
    ) {
      changedFields = [];
      trustedFrame?.current?.teardown();
      window.QuickstreamAPI.init({ publishableApiKey: paymentReferenceData?.westpacPublishableApiKey });
      const height = getFrameHeight(isPaymentFrequencyMonthly);
      const options = {
        config: {
          supplierBusinessCode: "RACIAPI",
        },
        body: {
          style: {
            width: "100%",
            height: "100%",
          },
        },
        labels: {
          style: {
            ...labelStyle,
            "margin-bottom": "0.5rem",
          },
        },
        iframe: {
          width: "100%",
          height,
          style: {
            width: "100%",
            height,
          },
        },
        cardholderName: {
          label: "Name on card",
          placeholder: "e.g. John Smith",
          style: inputStyle,
        },
        cardNumber: {
          label: "Card number",
          placeholder: "e.g. 1234 5678 1234 5678",
          style: inputStyle,
        },
        expiryDateMonth: {
          style: inputStyle,
        },
        expiryDateYear: {
          style: inputStyle,
        },
        cvn: {
          hidden: isPaymentFrequencyMonthly,
          label: "CVC",
          showHelp: false,
          placeholder: "xxx",
          style: inputStyle,
        },
      };
      window.QuickstreamAPI.creditCards.createTrustedFrame(options, createTrustedFrameHandler);
    }

    return () => {
      isMounted = false;
    };
  }, [
    isCreditCardSelected,
    isPaymentFrequencyMonthly,
    paymentReferenceData?.westpacPublishableApiKey,
    paymentReferenceDataIsError,
    isMobile,
  ]);

  return {
    westpacIFrameProps,
    trustedFrame: trustedFrame,
    onSubmitCreditCard,
    showAuthorisationCard,
    isFormFilledOut: showAuthorisationCard,
    isError,
    isEditLocked,
    policyNumber,
    onDoneClick: () => handleUnsuccessfulSubmit(policyNumber),
    onTryAgainClick: () => openTryAgainDialogFunction(false),
  };
};

export default useWestpacScript;
